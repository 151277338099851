import exceljs from 'exceljs'
import { saveAs } from 'file-saver'

export default async function exportExcel (fileName, sheets) {
  const workbook = new exceljs.Workbook()

  for (const sheet of sheets) {
    const worksheet = workbook.addWorksheet(sheet.name)
    worksheet.columns = sheet.headers

    sheet.data.forEach(d => {
      worksheet.addRow(d)
    })

    worksheet.getRow(1).font = {
      bold: true,
      color: { argb: '00000000' }
    }
  }

  const xls64 = await workbook.xlsx.writeBuffer({ base64: true })
  saveAs(
    new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    fileName
  )
}
